import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';

//Api Actions
import { getCandidateListData } from '../../actions/CandidateList';
import { REQUEST_GET_CANDIDATE_INFO } from '../../actions/actionTypes';
import {
  getGBADetails,
  upsertCandidateDetailsById,
  downloadCandidateReports,
  getPsacandPeerAppraisalData,
  getPsacandPeerAppraisalDataById,
  downloadSelectionNoteReport,
  downloadCandidatePsychReport,
  getLEAPDetails,
  getAllScoresForCandInsights,
  getPSTScoresImages,
} from '../../actions/CandidateInfo';
import {
  getAllSelectionBoardUsers,
  assignPanelMembersToCand,
} from '../../actions/CalendarSelectionBoard';
import { getPBITokens } from '../../actions/Dashboard';

//Common Functions
import { getCandidatePreferences } from '../../components/private/CandidatePersonalDetails';

//Common Components
import BackRow from '../../components/common/BackRow';
import GenericTabs from '../../components/common/GenericTabs';
import {
  Tile,
  Select,
  SelectItem,
  Button,
  TextArea,
  Toggle,
} from 'carbon-components-react';
import NoContentBox from '../../components/common/NoContentBox';
import JDProcessOverlay from '../../components/common/JDProcessOverlay';
//Ui Components
import CandidateVideoInterview from './CandidateVideoInterview';
import CandidateEvaluationForm from './CandidateEvaluationForm';
import CandidateReports from './CandidateReports';
import CandidateGBADetails from './CandidateGBADetails';
import PresidentScholarshipEvalForm from './PresidentScholarshipEvalForm';
import CandidatePersonalInfo from '../../components/private/CandidatePersonalInfo';
import CandUniversityScholaship from '../../components/private/CandUniversityScholaship';
import CandCCAInternship from '../../components/private/CandCCAInternship';
import CandidatePsychometricTests from '../../components/private/CandidatePsychometricTests';
import CandAcademicDetails from '../../components/private/CandAcademicDetails';
import SuccErrNotification from '../../components/private/SuccErrNotification';
import CandidateInsights from '../../components/private/CandidateInsights';
import CandidateNotes from './CandidateNotes';
import MainLegend from '../../components/common/MainLegend';
import PSAssessmentCenterData from '../../components/private/PSAssessmentCenterData';
import PSAssessmentCenterReport from '../../components/private/PSAssessmentCenterReport';
import PSPsychologicalInterviewsData from '../../components/private/PSPsychologicalInterviewsData';
import PSPeerRatingsData from '../../components/private/PSPeerRatingsData';
import PSMeanScoresGraph from '../../components/private/PSMeanScoresGraph';
import PSPeerAppraisalReport from '../../components/private/PSPeerAppraisalReport';
import PSPeerAppraisalExecutiveSummary from '../../components/private/PSPeerAppraisalExecutiveSummary';
import {
  handleGetCognitiveScores,
  handleGetNeopirScoes,
  handleGetPsychologicalScores,
  handleGetGBAfitScores,
} from '../../components/private/CandiateMetricColorDetails';

//Common Packages
import moment from 'moment';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import './CandidateInfo.css';
import CandidateLEAPDetails from './CandidateLEAPDetails';
import CandidateAssessFeedback from './CandidateAssessFeedback';
import CandidateAssessStatus from './CandidateAssessStatus';
import CandidateSettings from './CandidateSettings';
import PSBriefingDeck from './PSBriefingDeck';
import CandidatePersonalSummary from './CandidatePersonalSummary';
import { BsStars } from 'react-icons/bs';
import { getCurrEnvironment } from '../common/common';
import { lowerAndTrim } from '../../utils/helpers';

class CandidateInfo extends Component {
  paths = [
    { id: 'csc', name: 'ARS', href: '/app/csc' },
    {
      id: 'applicants',
      name: 'Applicants',
      href: '/app/csc/candidate/list/all',
    },
    { id: 'applicant-info', name: 'Candidate Information', href: '' },
  ];
  constructor(props) {
    super(props);
    this.state = {
      panelInterviewDate: moment().toDate(),
      status: 'personal-info',
      psSelectedTab: 0,
      showEditBmsUnitBtn: false,
      psacItemsPerPage: 20,
      peerItemsPerPage: 20,
      psyItemsPerPage: 20,
      psacActivePage: 1,
      peerActivePage: 1,
      psyActivePage: 1,
      tabContent: [],
    };
    this.handleRedirect = React.createRef();
  }

  componentDidMount() {
    this.handleGetCandidateInfoAPI();
    this.props.getAllSelectionBoardUsers();
    this.handleRedirect &&
      this.handleRedirect.current &&
      this.handleRedirect.current.focus &&
      this.handleRedirect.current.focus();
  }

  componentDidUpdate(prevProps) {
    const { applicationInfo, user } = this.props;
    const { psStatus2ndLevel, selectionYear = 0 } = applicationInfo || {};
    const { roleId } = user || {};
    const { applicationInfo: prevPropApplInfo, user: prevUser } = prevProps;
    const {
      psStatus2ndLevel: prevPsStatus2ndLevel,
      selectionYear: prevSelectionYear,
    } = prevPropApplInfo || {};

    const { roleId: prevRoleId } = prevUser || {};
    if (
      roleId &&
      prevRoleId &&
      selectionYear &&
      prevSelectionYear &&
      psStatus2ndLevel &&
      prevPsStatus2ndLevel &&
      (roleId !== prevRoleId ||
        selectionYear !== prevSelectionYear ||
        psStatus2ndLevel !== prevPsStatus2ndLevel)
    ) {
      this.populateTabContent(roleId, selectionYear, psStatus2ndLevel);
    }
  }

  handleCurrentSelectedTab = () => {
    const { tabContent } = this.state;
    const { match, user } = this.props;
    const { params } = match || {};
    const { status } = params || {};
    if (status) {
      this.setState(
        {
          status:
            tabContent.findIndex(
              (i) => i.tabSelected === lowerAndTrim(status)
            ) <= 0
              ? 'personal-info'
              : status,
        },
        () => {
          if (this.state.status === 'psac-peer') {
            this.handleGetCandidatePSData();
          }
        }
      );
    }
  };

  convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onloadend = () => {
        const finalBase64 =
          fileReader?.result?.replace(/^data:.+;base64,/, '') || null;
        resolve(finalBase64);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  handleGetCandidatePSData = async () => {
    const {
      psSelectedTab,
      psacItemsPerPage,
      peerItemsPerPage,
      psyItemsPerPage,
      psacActivePage,
      peerActivePage,
      psyActivePage,
    } = this.state;
    const { match } = this.props;
    const { params } = match || {};
    const { jobId } = params || {};
    const data = {
      psacItemsPerPage,
      peerItemsPerPage,
      psyItemsPerPage,
      psacActivePage,
      peerActivePage,
      psyActivePage,
    };
    if (psSelectedTab == 0) {
      data.getSummaryPageDetails = true;
    }
    const { applicationInfo } = this.props || {};
    const { profileId } = applicationInfo || {};
    if (psSelectedTab == 1) {
      this.props.getPsacandPeerAppraisalDataById(profileId);
    } else if (psSelectedTab == 2) {
      this.props.getPsacandPeerAppraisalDataById(
        profileId,
        jobId,
        'peer-appraisal-report'
      );
    } else if (psSelectedTab == 0 || psSelectedTab == 3) {
      // data.fetchLatestData = true;
      data.year = jobId;
      this.props.getPsacandPeerAppraisalData(data);
      this.props.getPsacandPeerAppraisalDataById(profileId, jobId);
      if (psSelectedTab == 0) {
        // this.props.getPBITokens().then((res) => {
        //   let embedToken = '';
        //   let embedParams = (res && res.embedParams) || null;
        //   let reportsMap = {};

        //   if (embedParams) {
        //     embedToken =
        //       (res &&
        //         res.embedParams &&
        //         res.embedParams.embedToken &&
        //         res.embedParams.embedToken.token) ||
        //       '';

        //     if (
        //       embedParams.reportsDetail &&
        //       Array.isArray(embedParams.reportsDetail) &&
        //       embedParams.reportsDetail.length > 0
        //     ) {
        //       for (let i = 0; i < embedParams.reportsDetail.length; i++) {
        //         let reportId = embedParams.reportsDetail[i].reportId || i;
        //         reportsMap[reportId] = embedParams.reportsDetail[i];
        //       }
        //     }
        //   }
        //   this.setState({
        //     reportsMap,
        //     embedToken,
        //   });
        // });
        this.props.getPSTScoresImages(jobId).then((res) => {
          if (res && !res.error) {
            const { url } = res || {};
            this.setState({ psTScoresImgSrc: url });
          }
        });
      }
    }
  };

  handleGetCandidateInfoAPI = () => {
    const { match, user } = this.props;
    const { params } = match || {};
    const { appId, jobId } = params || {};
    const { currentYear } = user || {};
    const data = {
      year: currentYear,
      activePage: 1,
      itemsPerPage: 1000,
      searchKey: null,
      year: jobId,
    };
    this.props
      .getCandidateListData(
        data,
        appId && Number(appId),
        REQUEST_GET_CANDIDATE_INFO
      )
      .then((res) => {
        if (res && !res.error) {
          this.populateTabContent();
          const { applicationInfo, candidateInfo } = this.props || {};
          const {
            internship,
            psychologicalReports,
            mainInterviewer,
            wgiiiScoreInfo,
            wgctaScoreInfo,
            ranraInfo,
            psMainInterviewer,
            scenarioInfo,
            leapScores,
            taskResult,
          } = candidateInfo || {};
          const {
            profileId,
            remarks,
            bmsUnit,
            profile,
            pscSessionSlotStartTimeStamp,
            chairmanReview = false,
            selectionYear,
          } = applicationInfo || {};
          const { scenario } = scenarioInfo || {};
          const { boardId, createdAt } =
            (mainInterviewer &&
              Array.isArray(mainInterviewer) &&
              mainInterviewer.length > 0 &&
              mainInterviewer[0]) ||
            {};
          const { boardId: psBoardId, createdAt: psCreatedAt } =
            (psMainInterviewer &&
              Array.isArray(psMainInterviewer) &&
              psMainInterviewer.length > 0 &&
              psMainInterviewer[0]) ||
            {};
          let internshipData = {};
          if (
            internship &&
            Array.isArray(internship) &&
            internship.length > 0
          ) {
            internship.forEach((intD) => {
              const { internshipdetailsId, supervisorRemarks } = intD || {};
              if (supervisorRemarks) {
                internshipData[`comment-${internshipdetailsId}`] =
                  supervisorRemarks;
              }
            });
          }
          const cognitiveNumericalSc = handleGetCognitiveScores(taskResult, {
            wgiiiScoreInfo,
            wgctaScoreInfo,
            ranraInfo,
          });
          const neopirSc = handleGetNeopirScoes(taskResult);
          const psychologicalScores =
            handleGetPsychologicalScores(psychologicalReports);
          this.setState(
            {
              remarks,
              cognitiveNumericalSc,
              neopirSc,
              psychologicalScores,
              boardId: boardId ? boardId : 0,
              panelInterviewDate: createdAt
                ? moment(createdAt).toDate()
                : pscSessionSlotStartTimeStamp
                ? moment(pscSessionSlotStartTimeStamp).toDate()
                : '',
              ...internshipData,
              psBoardId: psBoardId ? psBoardId : 0,
              psPanelInterviewDate: psCreatedAt
                ? moment(psCreatedAt).toDate()
                : '',
              bmsUnit,
              showEditBmsUnitBtn: bmsUnit ? true : false,
              chairmanReview,
              scenario,
              leapScores: leapScores?.overallScores || [],
              taskResult,
            },
            () => {
              if (profileId) {
                this.handleGetCandidateGBADetails();
                if (selectionYear > 2023) {
                  // this.props.getLEAPDetails(profileId);
                  this.props.getAllScoresForCandInsights(profileId);
                }
              }
            }
          );
        }
      })
      .catch((e) => console.log('Error', e));
  };

  populateTabContent = (
    propRoleId,
    propSelectionYear,
    propPsStatus2ndLevel
  ) => {
    const { applicationInfo, user } = this.props;
    const { psStatus2ndLevel, selectionYear } = applicationInfo || {};
    const { roleId } = user || {};

    const currRoleId = propRoleId || roleId;
    const currSelectionYear = propSelectionYear || selectionYear;
    const currPsStatus2ndLevel = propPsStatus2ndLevel || psStatus2ndLevel;

    const tabContent = [];
    tabContent.push({
      tabSelected: 'personal-info',
      label: 'Personal Information',
      value: '',
      onClick: () => {
        this.handleTabClick('personal-info');
      },
    });

    tabContent.push(
      {
        tabSelected: 'appls',
        label: 'Applications',
        value: '',
        onClick: () => {
          this.handleTabClick('appls');
        },
      },
      {
        tabSelected: 'academic-res',
        label: 'Academic Results',
        value: '',
        onClick: () => {
          this.handleTabClick('academic-res');
        },
      },
      {
        tabSelected: 'cca-ach',
        label: 'CCA & Achievements',
        value: '',
        onClick: () => {
          this.handleTabClick('cca-ach');
        },
      }
    );

    if (currSelectionYear <= 2023) {
      tabContent.push({
        tabSelected: 'psychometric-tests',
        label: 'Psychometric Tests',
        value: '',
        onClick: () => {
          this.handleTabClick('psychometric-tests');
        },
      });
    }

    if (currSelectionYear > 2023) {
      tabContent.push({
        tabSelected: 'leap',
        label: 'LEAP',
        value: '',
        title: 'Leadership Enabling Preferences',
        onClick: () => {
          this.handleTabClick('leap');
        },
      });
    } else {
      tabContent.push({
        tabSelected: 'gba',
        label: 'GBA',
        value: '',
        onClick: () => {
          this.handleTabClick('gba');
        },
      });
    }

    tabContent.push(
      {
        tabSelected: 'video-int',
        label: 'Video Presentation',
        value: '',
        onClick: () => {
          this.handleTabClick('video-int');
        },
      },
      {
        tabSelected: 'reports',
        label: 'Reports',
        value: '',
        onClick: () => {
          this.handleTabClick('reports');
        },
      }
    );

    if (currSelectionYear > 2023 && (currRoleId === 1 || currRoleId === 3)) {
      tabContent.push(
        {
          tabSelected: 'assessment-status',
          label: 'Assessment Status',
          value: '',
          onClick: () => {
            this.handleTabClick('assessment-status');
          },
        },
        {
          tabSelected: 'assessment-feedback',
          label: 'Assessment Feedback',
          value: '',
          onClick: () => {
            this.handleTabClick('assessment-feedback');
          },
        }
      );
    }

    if (currRoleId === 1 || currRoleId === 6 || currRoleId === 7) {
      tabContent.push({
        tabSelected: 'personal-summary',
        label: (
          <div className="d-flex">
            <div>(BETA) AI Summary</div>
            <div className="ml-2">
              <BsStars
                size={17}
                style={{ color: 'rgb(255 191 0)', marginRight: '2px' }}
              />
            </div>
          </div>
        ),
        value: '',
        onClick: () => {
          this.handleTabClick('personal-summary');
        },
      });
    }

    if (currRoleId !== 8 && currRoleId !== 9 && currRoleId !== 10) {
      tabContent.push({
        tabSelected: 'cand-eval',
        label: 'Evaluation Form',
        value: '',
        onClick: () => {
          this.handleTabClick('cand-eval');
        },
      });
    }

    if (
      currPsStatus2ndLevel &&
      currPsStatus2ndLevel.toString() &&
      currPsStatus2ndLevel.toString().length > 0 &&
      currRoleId !== 8 &&
      currRoleId !== 9 &&
      currRoleId !== 10
    ) {
      tabContent.push({
        tabSelected: 'psac-peer',
        label: 'President’s Scholarship',
        value: '',
        onClick: () => {
          this.handleTabClick('psac-peer');
        },
      });
    }

    if (currRoleId == 1) {
      tabContent.push({
        tabSelected: 'settings',
        label: 'Settings',
        value: '',
        onClick: () => {
          this.handleTabClick('settings');
        },
      });
    }

    this.setState({ tabContent }, () => {
      this.handleCurrentSelectedTab();
    });
  };

  handleOnSupervisorsCommentChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleSubmitSupervisorComments = (internshipdetailsId) => {
    if (!(this.state && this.state[`comment-${internshipdetailsId}`])) {
      this.setState({
        [`invalid-${internshipdetailsId}`]: true,
      });
      return;
    } else {
      this.setState(
        {
          [`invalid-${internshipdetailsId}`]: false,
        },
        () => {
          const { match } = this.props;
          const { params } = match || {};
          const { appId } = params || {};
          const data = {
            appId,
            internshipDetails: {
              internshipdetailsId,
              supervisorRemarks: this.state[`comment-${internshipdetailsId}`],
            },
          };
          this.props
            .upsertCandidateDetailsById(data)
            .then((res) => {
              if (res && !res.error) {
                this.handleGetCandidateInfoAPI();
                this.setState(
                  {
                    [`success-${internshipdetailsId}`]: true,
                  },
                  () => {
                    setTimeout(() => {
                      this.setState({
                        [`success-${internshipdetailsId}`]: false,
                      });
                    }, 5000);
                  }
                );
              } else {
                this.setState({
                  [`error-${internshipdetailsId}`]: true,
                });
              }
            })
            .catch((e) => console.log('Error', e));
        }
      );
    }
  };

  handleGetCandidateGBADetails = () => {
    const { applicationInfo } = this.props;
    const { profileId } = applicationInfo || {};
    this.props
      .getGBADetails(profileId)
      .then((res) => {
        if (res && !res.error) {
          const { gbaDetails } = this.props;
          const { details } = gbaDetails || {};
          const { assessment_results } = details || {};
          let filteredFitScores = [];
          if (
            assessment_results &&
            Array.isArray(assessment_results) &&
            assessment_results.length > 0
          ) {
            filteredFitScores = assessment_results.filter(
              (r) => r && r.trait === 'Fit'
            );
          }
          const fitScores = handleGetGBAfitScores(filteredFitScores);

          this.setState({
            fitScores,
          });
        }
      })
      .catch((e) => console.log('Error', e));
  };

  handleTabClick = (status) => {
    this.setState(
      {
        status,
      },
      () => {
        const { history, match } = this.props;
        const { params } = match || {};
        const { appId, jobId, status: currentStatus } = params || {};
        const { push } = history || {};
        const { status: updatedStatus } = this.state;
        if (updatedStatus === 'psac-peer') {
          this.handleGetCandidatePSData();
        }
        if (push) {
          push(
            `/app/csc/candidate/${appId}/batch/${jobId}/status/${updatedStatus}`
          );
          this.setState({ reRenderContent: true });
          if (currentStatus === updatedStatus) {
            // rerendering the tab content when clicked on the tab.
            if (updatedStatus === 'personal-summary') {
              this?.childCandPersonalSummary?.renderOnMount();
            } else if (updatedStatus === 'gba') {
              this?.childGbaDetails?.renderOnMount();
            } else if (updatedStatus === 'leap') {
              this?.childLEAPDetails?.renderOnMount();
            } else if (updatedStatus === 'video-int') {
              this?.childCandVidInt?.renderOnMount();
            } else if (updatedStatus === 'reports') {
              this?.childCandReports?.renderOnMount();
            } else if (updatedStatus === 'cand-eval') {
              this?.childCandEvalForm?.renderOnMount();
            } else if (updatedStatus === 'assessment-status') {
              this?.childAssessStatus?.renderOnMount();
            } else if (updatedStatus === 'assessment-feedback') {
              this?.childAssessFeedback?.renderOnMount();
            }
          }
        }
      }
    );
  };

  onDatePickerChange = (date, name) => {
    this.setState({ [name]: date });
  };

  handleSubmitPanelInterview = (recordType) => {
    const { match } = this.props;
    const { params } = match || {};
    const { appId } = params || {};
    const { panelInterviewDate, boardId, psPanelInterviewDate, psBoardId } =
      this.state;
    const { isInterviewerNameValid } = this.state;
    let date = panelInterviewDate
      ? new Date(panelInterviewDate).getTime()
      : null;
    if (recordType === 'PS') {
      date = psPanelInterviewDate
        ? new Date(psPanelInterviewDate).getTime()
        : null;
    }
    const data = {
      appId,
      date,
      recordType,
    };
    if (recordType === 'PSC') {
      if (boardId) {
        data.boardId = boardId;
      } else {
        data.unAssigned = true;
      }
    } else if (recordType === 'PS') {
      if (psBoardId) {
        data.boardId = psBoardId;
      } else {
        data.unAssigned = true;
      }
    }
    this.props
      .assignPanelMembersToCand(data)
      .then((res) => {
        if (res && !res.error) {
          this.handleGetCandidateInfoAPI();
          this.setState(
            {
              successNotif: true,
              notifMsg: 'Your details have been updated successfully',
            },
            () => {
              setTimeout(() => {
                this.setState({
                  successNotif: false,
                  notifMsg: '',
                });
              }, 5000);
            }
          );
        } else {
          const { error } = res || {};
          const { code } = error || {};
          let errMsg = '';
          if (code === 'x0pa-101') {
            errMsg = 'This candidate has already attended PSC Interview.';
          } else if (code === 'x0pa-102') {
            errMsg =
              'Another interview has already been created for this timeslot.';
          } else if (code === 'x0pa-109') {
            errMsg =
              'This timeslot overlaps with the breaktime. Please choose a different timeslot.';
          } else if (code === 'x0pa-121') {
            errMsg =
              'As per CMS, interviewer is unavailable for the scheduled date. Pls check and retry';
          } else {
            errMsg =
              'An Error has ocurred while processing your request, Please try again later';
          }
          this.setState(
            {
              errorNotif: true,
              errMsg,
            },
            () => {
              setTimeout(() => {
                this.setState({
                  errorNotif: false,
                  errMsg: null,
                });
              }, 5000);
            }
          );
        }
      })
      .catch((e) => console.log('Error', e));
  };

  handleCloseNotifBtn = () => {
    this.setState({
      successNotif: false,
      errorNotif: false,
      notifMsg: '',
    });
  };

  handleChange = (e) => {
    const tab = e && e.target && e.target.value;
    this.setState(
      {
        [e.target.name]: e.target.value,
        peerActivePage: tab === 0 ? 1 : this.state.peerActivePage,
        peerItemsPerPage: tab === 0 ? 20 : this.state.peerItemsPerPage,
      },
      () => {
        const { status } = this.state;
        if (status === 'psac-peer') {
          this.handleGetCandidatePSData();
        }
      }
    );
  };

  handleSubmitRemarks = () => {
    const { remarks } = this.state;
    const { match } = this.props;
    const { params } = match || {};
    const { appId } = params || {};
    this.setState(
      {
        isValidRemarks: false,
      },
      () => {
        const { isValidRemarks } = this.state;
        if (!isValidRemarks) {
          const data = {
            appId,
            remarks,
          };
          this.props
            .upsertCandidateDetailsById(data)
            .then((res) => {
              if (res && !res.error) {
                this.handleGetCandidateInfoAPI();
                this.setState(
                  {
                    successNotif: true,
                    notifMsg: 'Your details have been updated successfully',
                  },
                  () => {
                    setTimeout(() => {
                      this.setState({
                        successNotif: false,
                        notifMsg: '',
                      });
                    }, 5000);
                  }
                );
              } else {
                this.setState({
                  errorNotif: true,
                });
              }
            })
            .catch((e) => console.log('Error', e));
        } else {
        }
      }
    );
  };

  handleExportCandidateDetails = () => {
    const { match, candidateInfo, applicationInfo } = this.props || {};
    const { params } = match || {};
    const { appId } = params || {};
    const { profile } = applicationInfo || {};
    const { fullName } = profile || {};
    this.setState(
      {
        isExporting: true,
      },
      () => {
        this.props
          .downloadCandidateReports(candidateInfo, appId)
          .then((pdf) => {
            if (pdf && !pdf.error) {
              const pdfDoc = URL.createObjectURL(pdf);
              const a = document.createElement('a');
              a.href = pdfDoc;
              a.download = `${fullName}-${moment().format('MM/DD/YYYY')}`;
              document.body.appendChild(a);
              a.click();
              setTimeout(() => {
                document.body.removeChild(a);
                window.URL.revokeObjectURL(pdfDoc);
                this.setState(
                  {
                    isExporting: false,
                    successNotif: true,
                    notifMsg: 'The pdf has been downloaded successfully.',
                  },
                  () => {
                    setTimeout(() => {
                      this.setState({
                        errorNotif: false,
                        errMsg: '',
                      });
                    }, 5000);
                  }
                );
              }, 1000);
            } else {
              this.setState(
                {
                  isExporting: false,
                  errorNotif: true,
                  errMsg:
                    'There was an error while downloading the pdf, Please try again later.',
                },
                () => {
                  setTimeout(() => {
                    this.setState({
                      errorNotif: false,
                      errMsg: '',
                    });
                  }, 5000);
                }
              );
            }
          })
          .catch((e) => {
            console.log('Error', e);
            this.setState(
              {
                isExporting: false,
                errorNotif: true,
                errMsg:
                  'There was an error while downloading the pdf, Please try again later.',
              },
              () => {
                setTimeout(() => {
                  this.setState({
                    errorNotif: false,
                    errMsg: '',
                  });
                }, 5000);
              }
            );
          });
      }
    );
  };

  handleExportPsychCandidateDetails = () => {
    const { match, candidateInfo, applicationInfo } = this.props || {};
    const { params } = match || {};
    const { appId } = params || {};
    const { profile } = applicationInfo || {};
    const { fullName } = profile || {};
    this.setState(
      {
        isExportEmailStarted: true,
      },
      () => {
        this.props
          .downloadCandidatePsychReport(candidateInfo, appId)
          .then((res) => {
            if (res && !res.error) {
              console.log('res', res);
              this.setState(
                {
                  successNotif: true,
                  notifMsg: res.message,
                  isExportEmailStarted: false,
                },
                () => {
                  setTimeout(() => {
                    this.setState({
                      errorNotif: false,
                      errMsg: '',
                      isExportEmailStarted: false,
                    });
                  }, 5000);
                }
              );
            } else {
              this.setState(
                {
                  errorNotif: true,
                  errMsg:
                    'There was an error while downloading the pdf, Please try again later.',
                  isExportEmailStarted: false,
                },
                () => {
                  setTimeout(() => {
                    this.setState({
                      errorNotif: false,
                      errMsg: '',
                      isExportEmailStarted: false,
                    });
                  }, 5000);
                }
              );
            }
          })
          .catch((e) => {
            console.log('Error', e);
            this.setState(
              {
                isExporting: false,
                errorNotif: true,
                errMsg:
                  'There was an error while downloading the pdf, Please try again later.',
              },
              () => {
                setTimeout(() => {
                  this.setState({
                    errorNotif: false,
                    errMsg: '',
                  });
                }, 5000);
              }
            );
          });
      }
    );
  };

  handlePSTabClick = (tab) => {
    this.setState(
      {
        psSelectedTab: tab,
        peerActivePage: tab === 0 ? 1 : this.state.peerActivePage,
        peerItemsPerPage: tab === 0 ? 20 : this.state.peerItemsPerPage,
      },
      () => {
        this.handleGetCandidatePSData();
      }
    );
  };

  handleSubmitBMSUnit = () => {
    const { bmsUnit } = this.state;
    const { match } = this.props;
    const { params } = match || {};
    const { appId } = params || {};
    const data = {
      bmsUnit,
      appId,
    };
    this.props
      .upsertCandidateDetailsById(data)
      .then((res) => {
        this.handleRedirect &&
          this.handleRedirect.current &&
          this.handleRedirect.current.focus &&
          this.handleRedirect.current.focus();
        if (res && !res.error) {
          this.handleGetCandidateInfoAPI();
          this.setState(
            {
              successNotif: true,
              notifMsg: 'Your details have been updated successfully',
            },
            () => {
              setTimeout(() => {
                this.setState({
                  successNotif: false,
                  notifMsg: '',
                });
              }, 5000);
            }
          );
        } else {
          this.setState({
            errorNotif: true,
          });
        }
      })
      .catch((e) => console.log('Error', e));
  };

  handleEditBMSUnit = () => {
    this.setState({
      showEditBmsUnitBtn: !this.state.showEditBmsUnitBtn,
    });
  };

  handlePeerPaginationChange = (data) => {
    const peerActivePage = data.page || 1;
    const peerItemsPerPage = data.pageSize || 10;
    this.setState({ peerActivePage, peerItemsPerPage }, () => {
      this.handleGetCandidatePSData();
    });
  };

  handlePSACPaginationChange = (data) => {
    const psacActivePage = data.page || 1;
    const psacItemsPerPage = data.pageSize || 10;
    this.setState({ psacActivePage, psacItemsPerPage }, () => {
      this.handleGetCandidatePSData();
    });
  };

  handlePsyPaginationChange = (data) => {
    const psyActivePage = data.page || 1;
    const psyItemsPerPage = data.pageSize || 10;
    this.setState({ psyActivePage, psyItemsPerPage }, () => {
      this.handleGetCandidatePSData();
    });
  };

  handleUnAssignPresidentsScholarship = () => {
    const { match } = this.props;
    const { params } = match || {};
    const { appId } = params || {};
    const data = {
      appId,
      date: null,
      recordType: 'PS',
      unAssigned: true,
    };
    this.props
      .assignPanelMembersToCand(data)
      .then((res) => {
        if (res && !res.error) {
          this.handleGetCandidateInfoAPI();
        }
      })
      .catch((e) => console.log('Error', e));
  };

  handleSelectionNoteExport = () => {
    const { match, applicationInfo } = this.props || {};
    const { params } = match || {};
    const { appId } = params || {};
    const { profile, selectionYear } = applicationInfo || {};
    const { fullName } = profile || {};
    this.setState(
      {
        isSelectionNoteExporting: true,
      },
      () => {
        this.props
          .downloadSelectionNoteReport(
            (selectionYear && Number(selectionYear)) || 0,
            (appId && Number(appId)) || 0
          )
          .then((pdf) => {
            if (pdf && !pdf.error) {
              const pdfDoc = URL.createObjectURL(pdf);

              const a = document.createElement('a');
              a.href = pdfDoc;
              a.download = `Selection-Note-${fullName}-${moment().format(
                'MM/DD/YYYY'
              )}`;
              document.body.appendChild(a);
              a.click();
              setTimeout(() => {
                document.body.removeChild(a);
                window.URL.revokeObjectURL(pdfDoc);
                this.setState(
                  {
                    isSelectionNoteExporting: false,
                    successNotif: true,
                    notifMsg: 'The pdf has been downloaded successfully.',
                  },
                  () => {
                    setTimeout(() => {
                      this.setState({
                        errorNotif: false,
                        errMsg: '',
                      });
                    }, 5000);
                  }
                );
              }, 1000);
            }
          })
          .catch((e) => {
            console.log('Error', e);
          });
      }
    );
  };

  handleChairmansReview = (e) => {
    const { match } = this.props;
    const { params } = match || {};
    const { appId } = params || {};
    const data = {
      appId,
      chairmanReview: e,
    };
    this.props
      .upsertCandidateDetailsById(data)
      .then((res) => {
        if (res && !res.error) {
          this.handleGetCandidateInfoAPI();
          this.setState(
            {
              successNotif: true,
              notifMsg: 'Your details have been updated successfully',
            },
            () => {
              setTimeout(() => {
                this.setState({
                  successNotif: false,
                  notifMsg: '',
                });
              }, 5000);
            }
          );
        } else {
          this.setState({
            errorNotif: true,
          });
        }
      })
      .catch((e) => console.log('Error', e));
  };

  render() {
    const {
      match,
      applicationInfo,
      history,
      candidateInfo,
      loading,
      infoLoading,
      panelInterviewers,
      panelInterviewLoader,
      mainInterviewer,
      user,
      gbaDetails,
      psListData,
      peerDetails,
      psacDetails,
      psSummaryData,
      candScoresObj,
    } = this.props;
    const { uCognify } = candScoresObj || {};
    const { Ucognifyassessment } = uCognify || {};
    const { overallScore: uCogOverallScore } = Ucognifyassessment || {};
    const { boardId: resBoardId } = mainInterviewer || {};
    const {
      profile,
      profileId,
      applicationNumber,
      nominationRanking,
      psStatus2ndLevel,
      selectionYear = 0,
    } = applicationInfo || {};
    let filteredPanelList = [];
    if (
      panelInterviewers &&
      Array.isArray(panelInterviewers) &&
      panelInterviewers.length > 0
    ) {
      filteredPanelList = panelInterviewers.filter((member) => {
        const { userinfo, boardId: propBoardId } = member || {};
        const { roleId: propRoleId } = userinfo || {};
        if (propRoleId == 7) {
          return true;
        }
        if (propRoleId == 100 && propBoardId == this.state.boardId) {
          return true;
        }
        if (propRoleId == 100 && propBoardId == this.state.psBoardId) {
          return true;
        }
        return false;
      });
    }
    const { roleId } = user || {};

    const { fullName, nric } = profile || {};
    const nricSubStr =
      nric &&
      nric.toString() &&
      nric.toString().length >= 6 &&
      nric.toString().substring(1, 5);
    const finalNric =
      nric && nricSubStr && nric.toString().replace(nricSubStr, 'xxxx');
    const { params } = match || {};
    const { jobId, appId } = params || {};
    const {
      panelInterviewDate,
      notifMsg,
      successNotif,
      errorNotif,
      fitScores,
      remarks,
      isValidRemarks,
      cognitiveNumericalSc,
      neopirSc,
      psychologicalScores,
      isInterviewerNameValid,
      boardId,
      errMsg,
      isExporting,
      psSelectedTab,
      psBoardId,
      psPanelInterviewDate,
      psacActivePage,
      peerActivePage,
      psyActivePage,
      psyItemsPerPage,
      psacItemsPerPage,
      peerItemsPerPage,
      isSelectionNoteExporting,
      chairmanReview,
      reportsMap,
      embedToken,
      psTScoresImgSrc,
      leapScores,
      taskResult,
      scenario,
      status,
      tabContent,
    } = this.state;
    const {
      ns,
      family,
      scholarship,
      universityAppln,
      universityApplnUnique,
      internship,
      satDetails,
      schoolDetails,
      polyResult,
      oLevelResult,
      nusHighSchool,
      ibDiploma,
      videoIntApplnObj,
      aLevelResult,
      achievementDetails,
      nonAcademic,
      ccatiering,
      CareerPreference,
      CountryPreference,
      CoursePreference,
      universityDetails,
      satSubTests,
      otherInstResult,
    } = candidateInfo || {};
    const { company: school, companyRel } =
      (schoolDetails &&
        Array.isArray(schoolDetails) &&
        schoolDetails.length > 0 &&
        schoolDetails[0]) ||
      {};
    const { displayName: schoolName2 } = companyRel || {};
    const { displayName: schoolName } = school || {};
    const gbaKeys = [
      {
        key: 'performanceBased',
        label: 'Performance Based',
      },
      {
        key: 'futureDriven',
        label: 'Future Driven',
      },
    ];
    if (roleId != 7 && roleId !== 8 && roleId !== 9 && roleId !== 10) {
      gbaKeys.push(
        {
          key: 'award',
          label: 'Award',
        },
        {
          key: 'average',
          label: 'Average',
        }
      );
    }
    let finalApplNumber = null;
    if (applicationNumber) {
      if (applicationNumber.includes('U')) {
        finalApplNumber = 'Undergraduate Candidate';
      } else if (applicationNumber.includes('M')) {
        finalApplNumber = 'Master’s candidate';
      } else if (applicationNumber.includes('P')) {
        finalApplNumber = 'Mid-Term candidate';
      }
    }

    const data = {
      CareerPreference,
      CountryPreference,
      CoursePreference,
      universityAppln: universityApplnUnique,
    };

    const preferencesData = getCandidatePreferences(data);
    const {
      finalCareerPreferences,
      finalCountryPreferences,
      finalCoursePreferences,
      finalUniversityPreferences,
    } = preferencesData || {};

    const currEnv = getCurrEnvironment();

    const selectedTab = tabContent?.findIndex(
      (itm) => itm?.tabSelected == status
    );

    return (
      <div>
        <div
          className={`mt-2 ${
            currEnv === 'local'
              ? 'csc-cand-card-fixed-local'
              : 'csc-cand-card-fixed'
          }`}>
          <Tile>
            <div>
              <div className="d-flex justify-content-between">
                <div className="">
                  <div
                    className="h5 font-weight-bold text-capitalize"
                    style={{ fontSize: '24px' }}>
                    {fullName}{' '}
                    <span className="text-uppercase">{`(${nric})`}</span>
                  </div>
                  <div className="d-flex ml-0 mb-2">
                    {schoolName && (
                      <div className="css-default-text-color f-w-b">
                        {schoolName === 'Others' || schoolName === 'OtherSchool'
                          ? schoolName2
                          : schoolName}
                      </div>
                    )}
                    <div className="ml-4">
                      <span className="css-default-text-color f-w-b">
                        School Rank:
                      </span>{' '}
                      {nominationRanking || '-'}
                    </div>
                  </div>
                </div>
                {(roleId === 2 || roleId === 6) && (
                  <div className="d-flex">
                    {roleId === 6 && (
                      <div>
                        <div>
                          <label className="bx--label">
                            For Chairman's Review
                          </label>
                        </div>
                        <Toggle
                          toggled={chairmanReview}
                          labelText=""
                          className="toggle-remv-margin"
                          onToggle={(e) => this.handleChairmansReview(e)}
                          labelA="No"
                          labelB="Yes"
                        />
                      </div>
                    )}
                    <div className="ml-2">
                      <Button onClick={this.handleExportPsychCandidateDetails}>
                        {this.state.isExportEmailStarted === true
                          ? '...Exporting'
                          : 'Export by email'}
                      </Button>
                    </div>
                    {psStatus2ndLevel &&
                      psStatus2ndLevel.toString() &&
                      psStatus2ndLevel.toString().length > 0 && (
                        <div className="ml-2">
                          <Button
                            kind="tertiary"
                            onClick={this.handleSelectionNoteExport}>
                            {isSelectionNoteExporting
                              ? 'Exporting...'
                              : 'Selection Note Export'}
                          </Button>
                        </div>
                      )}

                    <div className="ml-2">
                      <Button onClick={this.handleExportCandidateDetails}>
                        {isExporting === true
                          ? 'Exporting...'
                          : 'Export Candidate Data'}
                      </Button>
                    </div>
                  </div>
                )}
              </div>
              {(schoolName || nominationRanking) && <hr className="mt-2" />}
              {finalApplNumber && (
                <div className="css-default-text-color f-w-b mb-2">
                  {finalApplNumber}
                </div>
              )}
            </div>
          </Tile>
        </div>
        <div className="cand-card-margin-top">
          <BackRow paths={this.paths} />
          <input
            style={{ height: 0, opacity: 0 }}
            type="text"
            ref={this.handleRedirect}
          />
          {/* {isAttendedPscInterview === true && (
          <InlineNotification
            lowContrast
            title="This Candidate has already attended PSC Interview."
            iconDescription="describes the close button"
            kind="warning"
            hideCloseButton
            onCloseButtonClick={() => {}}
          />
        )} */}
          <SuccErrNotification
            successNotif={successNotif}
            errorNotif={errorNotif}
            notifMsg={notifMsg}
            handleCloseNotifBtn={this.handleCloseNotifBtn}
            errMsg={errMsg}
          />
        </div>
        <Tile className="mt-2 ">
          <Fragment>
            <div className="d-xl-flex justify-content-between">
              <div>
                <div className="css-default-text-color f-w-b mb-2">
                  Panel Interview
                </div>
                <div className="d-flex">
                  <div className="">
                    <Select
                      onChange={this.handleChange}
                      invalid={isInterviewerNameValid}
                      name="boardId"
                      value={boardId}
                      disabled={
                        resBoardId || !(roleId == 1 || roleId == 6)
                          ? true
                          : psStatus2ndLevel &&
                            psStatus2ndLevel.toString() &&
                            psStatus2ndLevel.toString().length > 0
                      }
                      invalidText="Please select atleast one option"
                      labelText="Main Interviewer">
                      <SelectItem text="Select" />
                      {filteredPanelList &&
                        Array.isArray(filteredPanelList) &&
                        filteredPanelList.length > 0 &&
                        filteredPanelList.map((panelMemb) => {
                          const {
                            boardId: propBoardId,
                            aliasName,
                            userinfo,
                            isHidden,
                          } = panelMemb || {};
                          const { email } = userinfo || {};
                          return (
                            email !== 'tan_ser_kiat@psc.gov.sg' &&
                            email !== 'juliuskoh@gmail.com' && (
                              <SelectItem
                                text={aliasName}
                                id={propBoardId}
                                value={propBoardId}
                                key={propBoardId}
                                hidden={
                                  isHidden === true && boardId != propBoardId
                                }
                              />
                            )
                          );
                        })}
                    </Select>
                  </div>
                  <div className="ml-3">
                    <div className="">
                      <label className="bx--label">Panel Interview Date</label>
                    </div>
                    <DatePicker
                      selected={panelInterviewDate}
                      onChange={(date) =>
                        this.onDatePickerChange(date, 'panelInterviewDate')
                      }
                      showTimeSelect
                      disabled={
                        resBoardId || !(roleId == 1 || roleId == 6)
                          ? true
                          : psStatus2ndLevel &&
                            psStatus2ndLevel.toString() &&
                            psStatus2ndLevel.toString().length > 0
                      }
                      timeFormat="HH:mm"
                      timeIntervals={15}
                      className="css-h-d-input"
                      timeCaption="time"
                      dateFormat="dd/MM/yyyy, h:mm aa"
                    />
                  </div>
                  {!resBoardId &&
                    (roleId == 1 || roleId == 6) &&
                    !psStatus2ndLevel && (
                      <div className="ml-3 mt-3">
                        <Button
                          onClick={() =>
                            this.handleSubmitPanelInterview('PSC')
                          }>
                          Save
                        </Button>
                      </div>
                    )}
                </div>
              </div>
              {psStatus2ndLevel &&
                psStatus2ndLevel.toString() &&
                psStatus2ndLevel.toString().length > 0 && (
                  <div>
                    <div className="css-default-text-color f-w-b mb-2">
                      President's Scholarship
                    </div>
                    <div className="d-flex">
                      <div className="">
                        <Select
                          onChange={this.handleChange}
                          invalid={isInterviewerNameValid}
                          name="psBoardId"
                          value={psBoardId}
                          disabled={
                            resBoardId || !(roleId == 1 || roleId == 6)
                              ? true
                              : false
                          }
                          invalidText="Please select atleast one option"
                          labelText="Main Interviewer">
                          <SelectItem text="Select" />
                          {filteredPanelList &&
                            Array.isArray(filteredPanelList) &&
                            filteredPanelList.length > 0 &&
                            filteredPanelList.map((panelMemb) => {
                              const {
                                boardId: propBoardId,
                                aliasName,
                                userinfo,
                                isHidden,
                              } = panelMemb || {};
                              const { email } = userinfo || {};
                              return (
                                email !== 'tan_ser_kiat@psc.gov.sg' &&
                                email !== 'juliuskoh@gmail.com' && (
                                  <SelectItem
                                    text={aliasName}
                                    id={propBoardId}
                                    value={propBoardId}
                                    key={propBoardId}
                                    hidden={
                                      isHidden === true &&
                                      psBoardId != propBoardId
                                    }
                                  />
                                )
                              );
                            })}
                        </Select>
                      </div>
                      <div className="ml-3">
                        <div className="">
                          <label className="bx--label">
                            Panel Interview Date
                          </label>
                        </div>
                        <DatePicker
                          selected={psPanelInterviewDate}
                          onChange={(date) =>
                            this.onDatePickerChange(
                              date,
                              'psPanelInterviewDate'
                            )
                          }
                          showTimeSelect
                          disabled={
                            resBoardId || !(roleId == 1 || roleId == 6)
                              ? true
                              : false
                          }
                          timeFormat="HH:mm"
                          timeIntervals={15}
                          className="css-h-d-input"
                          timeCaption="time"
                          dateFormat="dd/MM/yyyy, h:mm aa"
                        />
                      </div>
                      {!resBoardId && (roleId == 1 || roleId == 6) && (
                        <div className="ml-3 mt-3">
                          <Button
                            onClick={() =>
                              this.handleSubmitPanelInterview('PS')
                            }>
                            Save
                          </Button>
                        </div>
                      )}
                    </div>
                  </div>
                )}
            </div>
            <hr />

            <Fragment>
              <div className="h6 font-weight-bold mt-2 mb-2">
                Candidate Insights
              </div>
              <div className="bx--row">
                <div className="bx--col-lg-3 bx--col-md-4">
                  <CandidateInsights
                    scores={cognitiveNumericalSc}
                    label="Cognitive Ability Scores (Percentile)"
                    keys={[
                      {
                        key: 'numericalReasoning',
                        label: 'Numerical Reasoning',
                      },
                      {
                        key: 'criticalThinking',
                        label: 'Critical Thinking',
                      },
                    ]}
                  />
                  {selectionYear > 2023 && (
                    <div className="mt-2">
                      <div className="d-flex align-items-center">
                        <div
                          className={`csc-th--block border-radius-50 color-gray d-flex justify-content-center align-items-center`}>
                          <div className="">{uCogOverallScore ?? 'NA'}</div>
                        </div>
                        <div className="">
                          <div className="css-trait-top ml-1 h6">
                            Problem Solving & Numeracy Skills
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>

                {selectionYear > 2023 ? (
                  <div className="bx--col-lg-3 bx--col-md-4">
                    <Fragment>
                      <div>
                        <div
                          className="font-weight-bold mb-2 mt-2"
                          title="Leadership Enabling Preferences (LEAP)">
                          LEAP Scores
                        </div>
                      </div>
                      {leapScores &&
                        Array.isArray(leapScores) &&
                        leapScores.length > 0 &&
                        leapScores.map((res) => {
                          const { label, value, colourCode, stenScore } =
                            res || {};

                          return (
                            <div>
                              <div className="mt-2">
                                <div className="d-flex align-items-center">
                                  <div
                                    className={`csc-th--block border-radius-50 color-gray d-flex justify-content-center align-items-center`}
                                    style={{ background: colourCode }}>
                                    <div className="">{stenScore ?? 'NA'}</div>
                                  </div>
                                  <div className="">
                                    <div className="css-trait-top ml-1 h6 text-capitalize">
                                      {label}{' '}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                    </Fragment>
                  </div>
                ) : (
                  <div className="bx--col-lg-3 bx--col-md-4">
                    <CandidateInsights
                      scores={fitScores}
                      label="GBA Scores"
                      hideFutureDrivenAward={
                        roleId == 7 ||
                        roleId == 8 ||
                        roleId == 9 ||
                        roleId == 10
                      }
                      keys={gbaKeys}
                    />
                  </div>
                )}

                <div className="bx--col-lg-3 bx--col-md-4">
                  <CandidateInsights
                    scores={psychologicalScores}
                    label="Psychological Interview Rating"
                    keys={[
                      {
                        key: 'leadershipQualities',
                        label: 'Leadership Qualities',
                      },
                      {
                        key: 'intellectualQualities',
                        label: 'Intellectual Qualities',
                      },
                      {
                        key: 'characterAndValues',
                        label: 'Character and Values',
                      },
                      {
                        key: 'interpersonalSkills',
                        label: 'Interpersonal Skills',
                      },
                      {
                        key: 'resilience',
                        label: 'Resilience',
                      },
                    ]}
                  />
                </div>

                {selectionYear <= 2023 && (
                  <div className="bx--col-lg-3 bx--col-md-4">
                    <CandidateInsights
                      scores={neopirSc}
                      label="NEO PI-R"
                      keys={[
                        {
                          key: 'neuroticism',
                          label: 'Neuroticism',
                        },
                        {
                          key: 'extraversion',
                          label: 'Extraversion',
                        },
                        {
                          key: 'openness',
                          label: 'Openness',
                        },
                        {
                          key: 'agreeableness',
                          label: 'Agreeableness',
                        },
                        {
                          key: 'conscientiousness',
                          label: 'conscientiousness',
                        },
                      ]}
                    />
                  </div>
                )}
              </div>
              <hr />
              <MainLegend scenario={scenario} selectionYear={selectionYear} />
              <hr />
              <div className="font-weight-bold mb-2">Remarks</div>
              {roleId === 6 ? (
                <TextArea
                  labelText="Remarks"
                  name="remarks"
                  value={remarks}
                  id="remarks"
                  placeholder="Please enter remarks."
                  hideLabel
                  onChange={this.handleChange}
                  invalid={isValidRemarks}
                  invalidText="Please enter valid remarks"
                />
              ) : (
                <div>{remarks ? remarks : '-'}</div>
              )}
            </Fragment>
            {roleId === 6 && (
              <div className="mt-2">
                <Button
                  kind="primary"
                  size="small"
                  onClick={this.handleSubmitRemarks}>
                  Save
                </Button>
              </div>
            )}
          </Fragment>
        </Tile>
        <Tile className="mt-2">
          <div className="h6 font-weight-bold mt-2 mb-2">Top Preferences</div>
          <div className="bx--row mt-2">
            <div className="bx--col-lg-3 bx--col-md-2 mt-2">
              <div className="">
                <div className="font-weight-bold">Career</div>
                {finalCareerPreferences &&
                Array.isArray(finalCareerPreferences) &&
                finalCareerPreferences.length > 0 ? (
                  finalCareerPreferences.map((careerRes, index) => {
                    const { career } = careerRes || {};
                    const { displayName } = career || {};
                    return (
                      index < 5 && (
                        <div className="d-flex mt-2">
                          <div>{index + 1})</div>
                          <div className="ml-1 text-capitalize">
                            {displayName}
                          </div>
                        </div>
                      )
                    );
                  })
                ) : (
                  <div className="h6 mt-2">There are no career preferences</div>
                )}
              </div>
            </div>
            <div className="bx--col-lg-3 bx--col-md-2 mt-2">
              <div className="">
                <div className="font-weight-bold">University</div>
                {finalUniversityPreferences &&
                Array.isArray(finalUniversityPreferences) &&
                finalUniversityPreferences.length > 0 ? (
                  finalUniversityPreferences.map((univ, index) => {
                    const { company, otherUniversityIdRel } = univ || {};
                    const { displayName, companyName } = company || {};
                    const { displayName: otherUniversity } =
                      otherUniversityIdRel || {};
                    let finalUniversityName = '';
                    if (companyName === 'others') {
                      finalUniversityName = otherUniversity;
                    } else {
                      finalUniversityName = displayName;
                    }
                    return (
                      index < 5 && (
                        <div className="d-flex mt-2">
                          <div>{index + 1})</div>
                          <div className="ml-1 text-capitalize">
                            {finalUniversityName}
                          </div>
                        </div>
                      )
                    );
                  })
                ) : (
                  <div className="h6 mt-2">
                    There are no university preferences
                  </div>
                )}
              </div>
            </div>
            <div className="bx--col-lg-3 bx--col-md-2 mt-2">
              <div className="">
                <div className="font-weight-bold">Course</div>
                {finalCoursePreferences &&
                Array.isArray(finalCoursePreferences) &&
                finalCoursePreferences.length > 0 ? (
                  finalCoursePreferences.map((courseRes, index) => {
                    const {
                      subject,
                      otherCourseId,
                      otherCourse2Id,
                      course2Id,
                    } = courseRes || {};
                    const { displayName: course, subject: courseName } =
                      subject || {};
                    const { displayName: otherCourse } = otherCourseId || {};
                    const { displayName: otherCourse2 } = otherCourse2Id || {};
                    const { displayName: course2, subject: courseName2 } =
                      course2Id || {};
                    const finalCourse = course && course.toString();
                    const finalCourse2 = course2 && course2.toString();
                    const finalOtherCourse =
                      otherCourse && otherCourse.toString();
                    const finalOtherCourse2 =
                      otherCourse2 && otherCourse2.toString();
                    let finalDisplayName = '';
                    if (courseName === 'others') {
                      finalDisplayName = `${finalOtherCourse} ${
                        finalOtherCourse2 ? 'and' : ''
                      } ${finalOtherCourse2 ? finalOtherCourse2 : ''}`;
                    } else if (courseName2 === 'others') {
                      finalDisplayName = `${finalCourse} ${
                        finalOtherCourse2 ? 'and' : ''
                      } ${finalOtherCourse2 ? finalOtherCourse2 : ''}`;
                    } else {
                      finalDisplayName = `${finalCourse} ${
                        finalCourse2 ? 'and' : ''
                      } ${finalCourse2 ? finalCourse2 : ''}`;
                    }
                    return (
                      index < 5 && (
                        <div className="d-flex mt-2">
                          <div>{index + 1})</div>
                          <div className="ml-1">{finalDisplayName}</div>
                        </div>
                      )
                    );
                  })
                ) : (
                  <div className="h6 mt-2">There are no course preferences</div>
                )}
              </div>
            </div>
            <div className="bx--col-lg-3 bx--col-md-2 mt-2">
              <div className="">
                <div className="font-weight-bold">Country</div>
                {finalCountryPreferences &&
                Array.isArray(finalCountryPreferences) &&
                finalCountryPreferences.length > 0 ? (
                  finalCountryPreferences.map((countryRes, index) => {
                    const { country, otherCountryId } = countryRes || {};
                    const { displayName, countryName } = country || {};
                    const { displayName: otherCountry } = otherCountryId || {};
                    let finalCounry = '';
                    if (countryName === 'others') {
                      finalCounry = otherCountry;
                    } else {
                      finalCounry = displayName;
                    }
                    return (
                      index < 5 && (
                        <div className="d-flex mt-2">
                          <div>{index + 1})</div>
                          <div className=" ml-1 text-capitalize">
                            {finalCounry}
                          </div>
                        </div>
                      )
                    );
                  })
                ) : (
                  <div className="h6 mt-2">
                    There are no country preferences
                  </div>
                )}
              </div>
            </div>
          </div>
        </Tile>
        {(roleId === 6 || roleId === 7) && <CandidateNotes appId={appId} />}
        <Tile className="mt-2 css-vertical-min-h">
          <div className="bx--row">
            <div className="bx--col-lg-2 bx--col-md-2 cand-info-tabs">
              <GenericTabs
                className="xpa-cand-info--tabs xpa-c-tabs--vertical m-0"
                selected={selectedTab <= -1 ? 0 : selectedTab}
                tabContent={tabContent}
              />
            </div>
            <div className="bx--col-lg-10 bx--col-md-6 pr-1 pl-0">
              {status === 'personal-info' && (
                <CandidatePersonalInfo
                  appId={appId}
                  ns={ns}
                  family={family}
                  applicationInfo={applicationInfo}
                  psStatus2ndLevel={psStatus2ndLevel}
                  handleChange={this.handleChange}
                  bmsUnit={this.state.bmsUnit}
                  showEditBmsUnitBtn={this.state.showEditBmsUnitBtn}
                  isBMSUnitValid={this.state.isBMSUnitValid}
                  handleSubmitBMSUnit={this.handleSubmitBMSUnit}
                  roleId={roleId}
                  handleEditBMSUnit={this.handleEditBMSUnit}
                />
              )}
              {status === 'personal-summary' && (
                <CandidatePersonalSummary
                  history={history}
                  match={match}
                  appId={appId}
                  onRef={(ref) => (this.childCandPersonalSummary = ref)}
                />
              )}
              {status === 'appls' && (
                <CandUniversityScholaship
                  scholarship={scholarship}
                  universityAppln={universityAppln}
                />
              )}
              {status === 'academic-res' && (
                <CandAcademicDetails
                  ibDiploma={ibDiploma}
                  nusHighSchool={nusHighSchool}
                  oLevelResult={oLevelResult}
                  polyResult={polyResult}
                  aLevelResult={aLevelResult}
                  schoolDetails={schoolDetails}
                  satDetails={satDetails}
                  universityDetails={universityDetails}
                  satSubTests={satSubTests}
                  otherInstResult={otherInstResult}
                />
              )}
              {status === 'cca-ach' && (
                <CandCCAInternship
                  internship={internship}
                  achievementDetails={achievementDetails}
                  nonAcademic={nonAcademic}
                  ccatiering={ccatiering}
                  handleOnSupervisorsCommentChange={
                    this.handleOnSupervisorsCommentChange
                  }
                  handleSubmitSupervisorComments={
                    this.handleSubmitSupervisorComments
                  }
                  state={this.state}
                  roleId={roleId}
                  schoolDetails={schoolDetails}
                />
              )}

              {status === 'psychometric-tests' &&
                (taskResult ? (
                  <CandidatePsychometricTests taskResult={taskResult} />
                ) : (
                  <NoContentBox
                    hideTile
                    message="There are no details for your current selection"
                  />
                ))}

              {status === 'gba' && selectionYear <= 2023 && (
                <CandidateGBADetails
                  history={history}
                  match={match}
                  profileId={profileId}
                  onRef={(ref) => (this.childGbaDetails = ref)}
                />
              )}

              {status === 'leap' && selectionYear > 2023 && (
                <CandidateLEAPDetails
                  history={history}
                  match={match}
                  profileId={profileId}
                  leapScores={leapScores}
                  onRef={(ref) => (this.childLEAPDetails = ref)}
                />
              )}

              {status === 'video-int' && (
                <CandidateVideoInterview
                  appId={appId}
                  videoIntApplnObj={videoIntApplnObj}
                  history={history}
                  match={match}
                  profileId={profileId}
                  selectionYear={selectionYear}
                  onRef={(ref) => (this.childCandVidInt = ref)}
                />
              )}

              {status === 'reports' && (
                <CandidateReports
                  appId={appId}
                  gbaDetails={gbaDetails}
                  onRef={(ref) => (this.childCandReports = ref)}
                />
              )}
              {status === 'assessment-status' && (
                <CandidateAssessStatus
                  selectionYear={selectionYear}
                  profileId={profileId}
                  onRef={(ref) => (this.childAssessStatus = ref)}
                />
              )}
              {status === 'assessment-feedback' && (
                <CandidateAssessFeedback
                  profileId={profileId}
                  onRef={(ref) => (this.childAssessFeedback = ref)}
                />
              )}
              {status === 'cand-eval' && (
                <CandidateEvaluationForm
                  applicationInfo={applicationInfo}
                  appId={appId}
                  history={history}
                  handleGetCandidateInfoAPI={this.handleGetCandidateInfoAPI}
                  handleUnAssignPresidentsScholarship={
                    this.handleUnAssignPresidentsScholarship
                  }
                  onRef={(ref) => (this.childCandEvalForm = ref)}
                />
              )}
              {status === 'psac-peer' && (
                <div>
                  <div className="mb-3 d-xl-none d-md-block d-xs-block d-lg-block">
                    <Select
                      onChange={this.handleChange}
                      name="psSelectedTab"
                      value={psSelectedTab}
                      labelText="Select">
                      <SelectItem text="Summary of Scores" value="0" />
                      <SelectItem text="Assessment Centre Report" value="1" />
                      <SelectItem text="Peer Appraisal Report" value="2" />
                      <SelectItem
                        text="Peer Appraisal Executive Summary"
                        value="3"
                      />
                      <SelectItem text="PS Briefing Deck" value="4" />
                      <SelectItem text="Candidate Evaluation Form" value="5" />
                    </Select>
                  </div>
                  <div className="mb-3 d-none d-xl-block">
                    <GenericTabs
                      className="xpa-psc-appr-w"
                      selected={psSelectedTab}
                      tabContent={[
                        {
                          label: 'Summary of Scores',
                          value: '',
                          onClick: () => {
                            this.handlePSTabClick(0);
                          },
                        },
                        {
                          label: 'Assessment Centre Report',
                          value: '',
                          onClick: () => {
                            this.handlePSTabClick(1);
                          },
                        },
                        {
                          label: 'Peer Appraisal Report',
                          value: '',
                          onClick: () => {
                            this.handlePSTabClick(2);
                          },
                        },
                        {
                          label: 'Peer Appraisal Executive Summary',
                          value: '',
                          onClick: () => {
                            this.handlePSTabClick(3);
                          },
                        },
                        {
                          label: 'PS Briefing Deck',
                          value: '',
                          onClick: () => {
                            this.handlePSTabClick(4);
                          },
                        },
                        {
                          label: 'Candidate Evaluation Form',
                          value: '',
                          onClick: () => {
                            this.handlePSTabClick(5);
                          },
                        },
                      ]}
                    />
                  </div>
                  {psSelectedTab == 0 && (
                    <div>
                      <PSMeanScoresGraph
                        psListData={psListData}
                        selectionYear={selectionYear}
                        reportsMap={reportsMap}
                        embedToken={embedToken}
                        psTScoresImgSrc={psTScoresImgSrc}
                      />
                      <PSPsychologicalInterviewsData
                        psyActivePage={psyActivePage}
                        psyItemsPerPage={psyItemsPerPage}
                        handlePsyPaginationChange={
                          this.handlePsyPaginationChange
                        }
                        psListData={psListData}
                      />
                      <PSAssessmentCenterData
                        psacActivePage={psacActivePage}
                        psacItemsPerPage={psacItemsPerPage}
                        handlePSACPaginationChange={
                          this.handlePSACPaginationChange
                        }
                        psListData={psListData}
                      />
                      <PSPeerRatingsData
                        handlePeerPaginationChange={
                          this.handlePeerPaginationChange
                        }
                        peerActivePage={peerActivePage}
                        peerItemsPerPage={peerItemsPerPage}
                        psListData={psListData}
                        peerDetailsById={psSummaryData}
                      />
                    </div>
                  )}
                  {psSelectedTab == 1 && (
                    <div>
                      <PSAssessmentCenterReport
                        psacDetails={psacDetails}
                        selectionYear={selectionYear}
                      />
                    </div>
                  )}
                  {psSelectedTab == 2 && (
                    <div>
                      <PSPeerAppraisalReport
                        peerDetails={peerDetails}
                        psSummaryData={psSummaryData}
                      />
                    </div>
                  )}
                  {psSelectedTab == 3 && (
                    <div>
                      <PSPeerAppraisalExecutiveSummary
                        profileId={profileId}
                        peerDetails={psListData}
                        peerDetailsById={psSummaryData}
                        handlePeerPaginationChange={
                          this.handlePeerPaginationChange
                        }
                        peerActivePage={peerActivePage}
                        peerItemsPerPage={peerItemsPerPage}
                      />
                    </div>
                  )}
                  {psSelectedTab == 4 && (
                    <div>
                      <PSBriefingDeck />
                    </div>
                  )}
                  {psSelectedTab == 5 && (
                    <div>
                      <PresidentScholarshipEvalForm
                        appId={appId}
                        applicationInfo={applicationInfo}
                        handleGetCandidateInfoAPI={
                          this.handleGetCandidateInfoAPI
                        }
                        psStatus2ndLevel={psStatus2ndLevel}
                      />
                    </div>
                  )}
                </div>
              )}

              {status === 'settings' && (
                <CandidateSettings
                  applicationInfo={applicationInfo}
                  appId={appId}
                  handleGetCandidateInfoAPI={this.handleGetCandidateInfoAPI}
                />
              )}
            </div>
          </div>
        </Tile>
        <JDProcessOverlay
          show={loading || infoLoading || panelInterviewLoader}
          message="processing..."
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  applicationInfo: state.CandidateList.applicationInfo,
  candidateInfo: state.CandidateList.candidateInfo,
  gbaDetails: state.CandidateInfo.gbaDetails,
  loading: state.CandidateList.loading,
  infoLoading: state.CandidateInfo.loading,
  panelInterviewers: state.CalendarSelectionBoard.panelInterviewers,
  panelInterviewLoader: state.CalendarSelectionBoard.loading,
  mainInterviewer: state.CalendarSelectionBoard.mainInterviewer,
  user: state.x0paDuser.user,
  psListData: state.CandidateInfo.psListData,
  peerDetails: state.CandidateInfo.peerDetails,
  psacDetails: state.CandidateInfo.psacDetails,
  psSummaryData: state.CandidateInfo.psSummaryData,
  // leapDetails: state.CandidateInfo.leapDetails,
  candScoresObj: state.CandidateInfo.candScoresObj,
});

const mapDispatchToProps = {
  getCandidateListData,
  getGBADetails,
  getAllSelectionBoardUsers,
  upsertCandidateDetailsById,
  assignPanelMembersToCand,
  downloadCandidateReports,
  getPsacandPeerAppraisalData,
  getPsacandPeerAppraisalDataById,
  downloadSelectionNoteReport,
  downloadCandidatePsychReport,
  getPBITokens,
  getLEAPDetails,
  getAllScoresForCandInsights,
  getPSTScoresImages,
};

export default connect(mapStateToProps, mapDispatchToProps)(CandidateInfo);
